











import { mapState } from 'vuex';
import CopyStakeStreamingScheduleTable from '@/views/CopyStake/CopyStakeStreamingSchedule/CopyStakeStreamingScheduleTable.vue';

export default {
  name: 'CopyStakeStreamingScheduleView',
  components: {
    CopyStakeStreamingScheduleTable
  },
  computed: {
    ...mapState('FeatureFlags', ['features', 'loading']),
    ...mapState('app', ['isSuperAdmin']),

    isEnabledCopyStake(): boolean {
      return (
        this.features.find((feature) => feature.name === 'live_streamer')
          ?.enabled === true
      );
    }
  },
  watch: {
    loading: {
      handler(value: boolean): void {
        if (value || this.isEnabledCopyStake || this.isSuperAdmin) return;

        this.$router.push('/');
      },
      immediate: true
    }
  }
};
